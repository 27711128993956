import { Link, graphql, useStaticQuery } from "gatsby"
import React from "react"
import Navbar from "../components/App/Navbar"
import BlogSidebar from "../components/BlogContent/BlogSidebar"
import Layout from "../components/App/Layout"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function MarkdownTemplate({ data: { markdownRemark } }) {
  const { frontmatter, html } = markdownRemark
  const contentimage = getImage(frontmatter.img)
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle={frontmatter.title}
        homePageText="Home"
        homePageUrl="/"
        activePageText={frontmatter.title}
      />
      <section className="content-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="content">
                <div className="article-image">
                  <GatsbyImage image={contentimage} alt={frontmatter.title} />
                </div>
                <div
                  className="privacy-policy-content"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        img {
          childImageSharp {
            gatsbyImageData(formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  }
`
